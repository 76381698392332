import './theming-marker.scss';
import { ScrollCaptain } from 'scroll-captain';

class ThemingMarker {
    constructor(elements, options) {
        // Set default configuration options
        const defaults = {
            index: null,
            initAttr: 'data-themingmarker', // Attribute to identify markers
            themingAttr: 'data-themingmarker-theming', // Attribute for theming state
            lightnessAttr: 'data-themingmarker-lightness', // Attribute for lightness level
            targetValue: 'target', // Target identifier value
            nextThemeValue: 'next' // Target identifier value
        };

        this.settings = Object.assign({}, defaults, options);
        
        // Elements
        this.$$markers = elements;

        this.markersState = {};
        this.initializedCount = 0;
        this.animation = [];
    }
    
    initialize() {
        this.prepareDynamicThemes();

        // Initialize each marker with its state and effects
        this.$$markers.forEach(($marker, index) => {
            this.setMarkerState($marker, index);
            this.initEffect($marker, index);
        });

        // Set up event listeners for theming updates
        this.setEvents();
    }

    prepareDynamicThemes() {
        this.$$markers.forEach(($marker, index, markers) => {
            // Find the next marker, if it exists
            const nextMarker = markers[index + 1];
            
            // Check if the current marker should set the next theme and if a next marker exists
            if (nextMarker && $marker.getAttribute(this.settings.initAttr) === this.settings.nextThemeValue) {
                // Set the theme and lightness of the next marker to the current marker
                $marker.setAttribute(this.settings.initAttr, nextMarker.getAttribute(this.settings.initAttr));
                $marker.setAttribute(this.settings.lightnessAttr, nextMarker.getAttribute(this.settings.lightnessAttr));
            }
        });
    }     

    setMarkerState ($marker, index, progress) {        
        if (!this.markersState[index]) {
            // Set initial state for marker if not already initialized
            const theming = $marker.getAttribute(this.settings.initAttr);
            const lightness = $marker.getAttribute(this.settings.lightnessAttr);

            this.markersState[index] = {
                active: false,
                progress: 0,
                theming: theming,
                lightness: lightness
            };
        } else {
            // Update state with current progress
            this.markersState[index].active = progress > 0;
            this.markersState[index].progress = progress;
        }
    }

    updateTheming() {
        let highestProgress = -1;
        let highestIndex = -1;
    
        // Determine the marker with the highest progress within range
        for (let index in this.markersState) {
            const entry = this.markersState[index];
    
            if (entry.active) {
                if (entry.progress > 0 && entry.progress < 1) {
                    if (entry.progress > highestProgress) {
                        highestProgress = entry.progress;
                        highestIndex = index;
                    }
                } else if (highestProgress === -1 && entry.progress === 1) {
                    if (index > highestIndex) {
                        highestIndex = index;
                    }
                }
            }
        }
    
        // Apply theming and lightness based on the highest active marker
        if (highestIndex > -1) {
            document.documentElement.setAttribute(this.settings.themingAttr, this.markersState[highestIndex].theming);
            document.documentElement.setAttribute(this.settings.lightnessAttr, this.markersState[highestIndex].lightness);
        }
    }

    initEffect($marker, index) {
        // Set initial theming attribute on document root
        document.documentElement.setAttribute(this.settings.initAttr, this.settings.targetValue);        

        // Initialize scroll effect for each marker
        this.animation[index] = new ScrollCaptain($marker, {
            initAttr: this.settings.initAttr, // Attribute to apply to initialized effect
            triggerIndex: this.settings.index, // Index to identify active effect
            bottom: '-50%', // Offset for when effect triggers
            onEnter: (progress) => {                             
                this.setMarkerState($marker, index, progress);
                this.updateTheming();
            },
            onLeave: (progress) => {                             
                this.setMarkerState($marker, index, progress);
                this.updateTheming();
            },
            onInit: (progress) => {     
                this.setMarkerState($marker, index, progress);
                this.initializedCount++;

                // Trigger event when all effects are initialized
                if (this.initializedCount === this.$$markers.length) {
                    const effectsInitializedEvent = new Event('effectsInitialized');
                    window.dispatchEvent(effectsInitializedEvent);
                }
            }
        });
    }

    setEvents() {
        // Listen for effectsInitialized event to apply theming updates
        window.addEventListener('effectsInitialized', () => {
            this.updateTheming();
        });

        // Accessibility – Update scroll animation
        window.addEventListener('programmaticScrollDetected', () => {            
            this.$$markers.forEach(($marker, index) => {
                this.animation[index].update();
            });
        });
    }
}

export { ThemingMarker };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$roots = $context.querySelectorAll('[data-themingmarker]');
        const $rootAPI = new ThemingMarker($$roots);
        $rootAPI.initialize();
    }
});
